import { useState } from 'react'
import { useRouter } from 'next/router'
import Avatar from 'elements/Avatar'
import Link from 'next/link'
import { appRoutes } from 'config'
import { useAuthentication } from 'hooks/useAuthentication'
import { requestToLogout } from 'services/AuthService';

type Props = {
  isTransparent?:  boolean
}

const Header = ({ isTransparent = false }: Props): JSX.Element => {
  const { pathname } = useRouter()
  // const isFeed = pathname === appRoutes.FEED;
  const isDrops = pathname === appRoutes.MEMORY_BOX + '/[[...slug]]'
  const isSettings = pathname === appRoutes.SETTINGS;

  const [openProfile, setOpenProfile] = useState(false)
  const [openMenu, setOpenMenu] = useState(false)

  const { authenticatedUser } = useAuthentication();

  const handleLogoutBtnClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault()
    requestToLogout()
  }

  return (
    <nav className={isTransparent? "bg-white fixed top-0 w-full border-b border-gray-200 z-10 shadow-sm": "bg-white fixed top-0 w-full border-b border-gray-200 z-10 shadow-sm"}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex">
            {/* Logo */}
            <Link href={appRoutes.HOME}>
              <a className="flex items-center">
                <img
                  className="block h-8 w-auto"
                  src="/logotype-light.svg"
                  alt="MindFeed"
                />
              </a>
            </Link>

            {/* Menu - Desktop */}
            <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
              {/* <Link href={appRoutes.FEED}>
                <a
                  className={
                    (isFeed
                      ? 'border-mf-blue text-mf-blue'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700') +
                    ' inline-flex items-center px-1 pt-1 border-b-2 text-sm font-bold'
                  }
                  aria-current="page"
                >
                  My Feed
                </a>
              </Link> */}
              <Link href={appRoutes.MEMORY_BOX}>
                <a
                  className={
                    (isDrops
                      ? 'border-mf-dark-blue text-mf-dark-blue'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700') +
                    ' inline-flex items-center px-1 pt-1 border-b-2 text-sm font-bold'
                  }
                >
                  My Memory Box
                </a>
              </Link>
            </div>
          </div>

          <div className="hidden sm:ml-6 sm:flex sm:items-center">
            {/* My Notifications */}
            {/* <button className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              <span className="sr-only">View notifications</span>
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                />
              </svg>
            </button> */}

            {/* My Profile */}
            <ul className="ml-3 relative text-center">
              <li className="font-bold text-gray-800" suppressHydrationWarning>
                {authenticatedUser?.getDisplayName() ?? 'Display Name'}
              </li>
              <li className="text-gray-600 text-sm" suppressHydrationWarning>
                {authenticatedUser?.getUsername() ?? '@username'}
              </li>
            </ul>

            <div className="ml-3 relative">
              <Avatar
                imgSrc={authenticatedUser?.getHigResolutionAvatarURL() ?? '/icons/icon-user.svg'}
                onClick={() => {
                  setOpenProfile(!openProfile)
                }}
                onClickAway={() => {
                  setOpenProfile(false)
                }}
              />
              <div
                className={
                  (openProfile ? 'visible' : 'invisible') +
                  ' origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black/5 focus:outline-none'
                }
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="user-menu-button"
                tabIndex={-1}
              >
                <Link href={appRoutes.PROFILE}>
                  <a
                    className="block px-4 py-2 text-sm text-gray-700"
                    role="menuitem"
                    tabIndex={-1}
                    id="user-menu-item-1"
                  >
                    Settings
                  </a>
                </Link>
                <a
                  onClick={(e) => handleLogoutBtnClick(e)}
                  href="#"
                  className="block px-4 py-2 text-sm text-gray-700"
                  role="menuitem"
                  tabIndex={-1}
                  id="user-menu-item-2"
                >
                  Sign out
                </a>
              </div>
            </div>

          </div>

          {/* Mobile - Toggle Menu Button */}
          <div className="-mr-2 flex items-center sm:hidden">
            <button
              type="button"
              className="bg-white inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-200"
              aria-controls="mobile-menu"
              aria-expanded="false"
              onClick={() => {
                setOpenMenu(!openMenu)
              }}
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="block h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
              <svg
                className="hidden h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      {/* Mobile - Menu Div */}
      <div
        className={(openMenu ? '' : 'hidden') + ' sm:hidden'}
        id="mobile-menu"
      >
        <div className="pt-4 pb-3 border-t border-gray-200 shadow-sm">

          {/* Profile */}
          <div className="flex items-center px-4 border-b border-gray-200 pb-3">
            <div className="shrink-0">
              {/* referrerPolicy is used to prevent 403 Forbidden errors for google profile pictures */}
              <img referrerPolicy="no-referrer" className="h-10 w-10 rounded-full" src={authenticatedUser?.getHigResolutionAvatarURL() ?? '/icons/icon-user.svg'} alt="Profile Picture" />
            </div>
            <div className="ml-3">
              <div className="text-base font-medium text-gray-800">
                {authenticatedUser?.name ?? 'Name'}
              </div>
              <div className="text-sm font-medium text-gray-500">
                {authenticatedUser?.email ?? 'email'}
              </div>
            </div>
            {/* <button className="ml-auto bg-white shrink-0 p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              <span className="sr-only">View notifications</span>
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                />
              </svg>
            </button> */}
          </div>

          <nav className="pt-2 pb-3 space-y-1 border-b border-gray-200">
            {/* <Link href={appRoutes.FEED}>
              <a
                className={
                  (isFeed
                    ? 'bg-indigo-50 border-mf-blue text-mf-blue'
                    : 'border-transparent text-gray-600 hover:border-gray-300 hover:text-gray-800 ') +
                  ' block pl-3 pr-4 py-2 border-l-4 text-base font-medium'
                }
                aria-current="page"
              >
                My Feed
              </a>
            </Link> */}
            <Link href={appRoutes.MEMORY_BOX}>
              <a
                className={
                  (isDrops
                    ? 'bg-indigo-50 border-mf-blue text-mf-blue'
                    : 'border-transparent text-gray-600 hover:border-gray-300 hover:text-gray-800 ') +
                  ' block pl-3 pr-4 py-2 border-l-4 text-base font-medium'
                }
                aria-current="page"
              >
                My Memory Box
              </a>
            </Link>
            <Link href={appRoutes.PROFILE}>
              <a
                className={
                  (isSettings
                    ? 'bg-indigo-50 border-mf-blue text-mf-blue'
                    : 'border-transparent text-gray-600 hover:border-gray-300 hover:text-gray-800 ') +
                  ' block pl-3 pr-4 py-2 border-l-4 text-base font-medium'
                }
                aria-current="page"
              >
                Settings
              </a>
            </Link>
          </nav>
          {/* Logout */}
          <div className="mt-3 space-y-1">
            <a
              onClick={(e) => handleLogoutBtnClick(e)}
              href="#"
              className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
            >
              Sign out
            </a>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Header
