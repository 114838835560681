import React, { FC } from 'react'
import ClickAwayListener from 'listeners/ClickAwayListener'
import Image from 'next/image'

interface AvatarProps {
  imgSrc: string
  onClick?: () => void
  onClickAway?: () => void
}

const Avatar: FC<AvatarProps> = ({
  imgSrc = '/icons/icon-user.svg',
  onClick = () => {
    // Do nothing
  },
  onClickAway = () => {
    // Do nothing
  },
}): JSX.Element => {
  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <button
        type="button"
        className="h-10 w-10 max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-200"
        id="user-menu-button"
        aria-expanded="false"
        aria-haspopup="true"
        onClick={onClick}
      >
        <span className="sr-only">Open user menu</span>
        <Image
          className="h-10 w-10 rounded-full"
          src={imgSrc}
          alt="Avatar"
          layout="fill"
        />
      </button>
    </ClickAwayListener>
  )
}

export default Avatar
